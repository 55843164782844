/* You can add global styles to this file, and also import other style files */
/*@import 'app/theme';*/
/* You can add global styles to this file, and also import other style files */
/* devanagari */
@import "~bootstrap/dist/css/bootstrap.css";
/*@import "https://vioniko.com/css/style.default.css";*/
/*
Vioniko style header
*/
/*** HEADER ***/

.header { background: #3b6c8e; clear: both; height: 110px; }
.headerinner { margin-left: 260px; }
.header .logo { width: 260px; text-align: center; padding-top: 40px; float: left; }

.headmenu { list-style: none; }
.headmenu .dropdown-menu { border: 2px solid #3b6c8e; border-top: 0; margin: 0; }
.headmenu .nav-header {
  text-shadow: none; font-weight: normal; padding: 3px 15px; color: #999; font-size: 11px;
  text-transform: uppercase; }
.headmenu .dropdown-menu::after {
  position: absolute; top: -6px; left: 45px; display: inline-block; border-right: 6px solid transparent;
  border-bottom: 6px solid white; border-left: 6px solid transparent; content: ''; }
.headmenu > li {
  display: inline-block; float: left; font-size: 14px; position: relative; border-right: 1px solid rgba(255,255,255,0.15); }
.headmenu > li:first-child { border-left: 1px solid rgba(255,255,255,0.15); }
.headmenu > li.odd { background: rgba(255,255,255,0.1); }
.headmenu > li.right { float: right; border-right: 0; }
.headmenu > li > a {
  min-width: 70px; position: relative; display: block; color: #fff;
  padding: 25px 20px 9px 20px; cursor: pointer; }
.headmenu > li > a:hover { text-decoration: none; }
.headmenu > li > a .count { position: absolute; top: 5px; right: 10px; opacity: 0.5; }
.headmenu > li > a:hover .count, .headmenu > li.open > a .count { opacity: 1; }
.headmenu > li > a .headmenu-label { display: block; margin: 2px 0 3px 0; opacity: 0.5; text-align: center; }
.headmenu > li > a:hover .headmenu-label, .headmenu > li.open > a .headmenu-label { opacity: 1; }

.headmenu > li > a .head-icon { width: 50px; height: 50px; display: block; margin: auto; opacity: 0.5; }
.headmenu > li > a:hover .head-icon, .headmenu > li.open a .head-icon { opacity: 1; }

.head-message { background-image: url('https://vioniko.com/images/icons/message.png'); }
.head-users { background-image: url('https://vioniko.com/images/icons/users.png'); }
.head-bar { background-image: url('https://vioniko.com/images/icons/bar.png'); }
.head-retorn { background-image: url('https://vioniko.com/images/icons/retorn.png'); }
.head-video { background-image: url('https://vioniko.com/images/icons/video.png'); }

.viewmore a { font-size: 11px; text-transform: uppercase; font-size: 11px !important; }

.newusers { min-width: 200px; }
.newusers li a:hover { background: #eee; }
.newusers li a::after { clear: both; display: block; content: ''; }
.newusers .userthumb { width: 35px; display: block; float: left; margin: 3px 10px 3px 0; }
.newusers strong { display: block; line-height: normal; }
.newusers small { color: #999; line-height: normal; }

.userloggedinfo { padding: 11px; color: #fff; }
.userloggedinfo img { padding: 3px; background: rgba(255,255,255,0.2); width: 85px; float: left; }
.userloggedinfo .userinfo { float: left; margin-left: 10px; }
.userloggedinfo .userinfo small { font-size: 11px; opacity: 0.6; color: #fff; font-family: sans-serif; font-style: italic; }
.userloggedinfo ul { list-style: none; margin-top: 5px; }
.userloggedinfo ul li { display: block; font-size: 11px; line-height: normal; margin-bottom: 1px; }
.userloggedinfo ul li a { padding: 4px 5px 3px 5px; color: #fff; line-height: normal; background: rgba(255,255,255,0.1); display: block; }
.userloggedinfo ul li a:hover { text-decoration: none; background: rgba(255,255,255,0.2); }

.no-borderradius .userloggedinfo .userinfo { float: none; margin-left: 92px; }


/* end vioniko header style*/

.ic-tm-racing:before {
  content: '\e900';
}
.ic-tm-ice-skating:before {
  content: '\e901';
}
.ic-tm-rugby:before {
  content: '\e902';
}
.ic-tm-football:before {
  content: '\e903';
}
.ic-tm-cricket:before {
  content: '\e904';
}
.ic-tm-track-field:before {
  content: '\e905';
}
.ic-tm-mma:before {
  content: '\e906';
}
.ic-tm-boxing:before {
  content: '\e907';
}
.ic-tm-rodeo:before {
  content: '\e908';
}
.ic-tm-gymnastics:before {
  content: '\e909';
}
.ic-tm-lacrosse:before {
  content: '\e90a';
}
.ic-tm-hockey:before {
  content: '\e90b';
}
.ic-tm-golf:before {
  content: '\e90c';
}
.ic-tm-concerts:before {
  content: '\e90d';
}
.ic-tm-theatre:before {
  content: '\e90e';
}
.ic-tm-ticket:before {
  content: '\e90f';
}
.ic-tm-oly:before {
  content: '\e910';
}
.ic-tm-softball:before {
  content: '\e911';
}
.ic-tm-wrestling:before {
  content: '\e912';
}
.ic-tm-tennis:before {
  content: '\e913';
}
.ic-tm-volleyball:before {
  content: '\e914';
}
.ic-tm-soccer:before {
  content: '\e915';
}
.ic-tm-basketball:before {
  content: '\e916';
}
.ic-tm-baseball:before {
  content: '\e917';
}
/* BEGIN global styles */
html {
  font-size: 100%;
  position: relative;
  min-height: 100%;
}
body {
  font-family: var(--siteFont) !important;
  font-size: calc(var(--siteFontSize) + 0.25 * (100vw - 80em) / 100) !important;
  font-size: -webkit-calc(var(--siteFontSize) + 0.25 * (100vw - 80em) / 100) !important;
  font-weight: var(--siteFontWeight) !important;
  line-height: 13.25px;
  letter-spacing: 0.02em;
  color: var(--siteFontColor);
  background: #f2f2f2 !important;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
}
#inviteeName ngb-typeahead-window {
  overflow-y: auto;
  max-height: 25em;
}
.unclickable {
  pointer-events: none;
  opacity: 0.5;
}
.form-control, .btn {
  line-height: 1.25;
}
.change-color {
  color: #1a87cc !important;
  font-weight: 500 !important;
  text-decoration: underline;
  cursor: pointer;
}
.hold-ticket round-progress {
  width: 32px !important;
  height: 32px !important;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
}
.hold-ticket svg path {
  stroke-width: 15;
  stroke: #fff !important;
}
.card-body {
  padding: 0px 15px;
}
.accordion .card-body {
  padding: 0;
}
.collapse.show {
  display: block;
  padding: 0;
}
button, input, optgroup, select, textarea {
  font-family: var(--siteFont);
}
.dropdown-item {
  font-family: var(--siteFont);
}
.btn {
  cursor: pointer;
}
#policy-terms ul.nav-tabs {
  background: #fff;
}
#policy-terms ul.nav-tabs li a {
  color: #84a7a6;
  padding: 18px;
}
#policy-terms ul.nav-tabs li .active {
  border-bottom: 2px solid #0c576c;
  color: #0c576c;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}
.ng2-auto-complete-wrapper {
  width: 100%;
  padding: 0;
}
.nl2br {
  white-space: pre-line;
  word-break: break-word;
}
/********************************* date picker widget ***************/
.mydp *:not(.mydpicon) {
  font-family: var(--siteFont) !important;
}
li.sub-menu-parent form my-date-picker .mydp {
  width: auto;
  outline: none;
  border: 0;
}
my-date-picker input.selection {
  position: relative !important;
  padding-right: 0 !important;
  font-weight: 600;
  float: right;
  color: #2e2e2e !important;
  vertical-align: text-bottom;
  line-height: 1.3em;
  text-align: left !important;
}
my-date-picker .mydp .selbtngroup {
  display: inline-block;
  font-size: 0;
  position: relative;
  white-space: nowrap;
  width: auto;
}
my-date-picker .mydp .selbtngroup button {
  border: 0;
}
my-date-picker .mydp .selection {
  background-color: #fff;
  cursor: context-menu;
}
my-date-picker .mydp input.selection {
  display: inline-block;
  width: 120px;
  cursor: pointer;
  -webkit-text-fill-color: transparent;
  color: transparent !important;
  text-shadow: 0 0 0 #2e2e2e;
  padding-left: 0px;
  text-overflow: unset !important;
}
my-date-picker .mydp .selectorarrow {
  background: #fff;
  border: 0;
  width: 300px;
  padding: 0.3em 0.8em 0.5em;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 5px;
}
my-date-picker .mydp .headermonthtxt {
  max-width: 70px;
}
my-date-picker .mydp .header {
  background-color: #fff;
  height: 35px;
}
my-date-picker .mydp .weekdaytitle {
  font-weight: 400;
  background-color: transparent;
  font-size: 0.8em;
  color: #777;
}
my-date-picker .mydp .currmonth {
  font-size: 1em;
  color: #2e2e2e;
  background-color: #fff !important;
}
my-date-picker .mydp .daycell {
  background: rgba(215, 215, 215, 0.3);
  padding: 0.3em;
}
my-date-picker .mydp .daycell .nextmonth, my-date-picker .mydp .daycell .prevmonth {
  color: #d8d8d8;
  background: transparent;
}
my-date-picker .mydp .selectorarrowright:after, my-date-picker .mydp .selectorarrowright:before {
  display: none;
}
my-date-picker .mydp .btnclearenabled:hover, my-date-picker .mydp .btnpickerenabled:hover, my-date-picker .mydp .headertodaybtnenabled:hover, my-date-picker .mydp .tablesingleday:hover {
  background-color: rgba(139, 218, 244, 0);
}
my-date-picker .mydp .btnpicker:focus {
  background-color: transparent;
}
my-date-picker .mydp .selectedday div {
  border-radius: 0px;
  background-color: #1a87cc !important;
  color: #fff;
  border: 0;
}
my-date-picker .mydp .headertodaybtn {
  display: none;
}
my-date-picker .mydp .selector:focus {
  outline: 0;
  border: 0;
}
.dropdown-menu {
  line-height: 2em;
  margin-top: 0;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: 1em;
}
.dropdown-item {
  color: #2e2e2e;
  font-family: var(--siteFont);
  font-size: 1em;
}
.alert-custom {
  width: 84.5%;
  margin: auto;
  font-size: 0.9em;
  border-radius: 0;
  margin-bottom: 1.8em;
  line-height: 1.5em;
}
.modal-content {
  border: 0;
  border-radius: 0;
  outline: 0;
}
.loader {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 45%;
  left: 45%;
}
/********************************************* Ticket list for search result page ***/
.ticket-list .card {
  border-radius: 0;
  border: none;
  padding: 0;
  margin-top: 1.5em;
}
.ticket-list .card:first-child {
  border-radius: 0;
  margin-top: 0px;
}
.ticket-list .card-header {
  padding: 0;
}
.ticket-list .card-header h5, .ticket-list .card-header .h5 {
  font-size: 1em;
}
.ticket-list .card-block {
  -moz-box-flex: 1;
  flex: 1 1 auto;
  padding: 1.25rem;
  border-radius: 0;
}
.ticket-list .card-header a:hover, .ticket-list .card-header a:focus {
  text-decoration: none;
  outline: none;
}
/******************************* ToolTip *******/
.tooltip-inner {
  background: #254649;
}
.tooltip.bs-tether-element-attached-top .tooltip-inner:before, .tooltip.tooltip-bottom .tooltip-inner:before {
  border-bottom-color: #254649;
}
.tooltip.show {
  opacity: 1;
}
/*********************************************************** Stylish Input for top search bar ***/
.stylish-input-group ngb-typeahead-window.dropdown-menu {
  top: -3px !important;
  /*width: 90%;
    */
  border-radius: 0;
  font-size: 1em;
  border-top: 0;
  overflow-y: auto;
  max-height: 250px;
  left: 0;
  right: 76px;
}
.stylish-input-group ngb-typeahead-window.dropdown-menu .dropdown-item {
  white-space: inherit;
  line-height: 1.8em;
}
.dropdown-item.active, .dropdown-item:active {
  color: #2e2e2e;
  text-decoration: none;
  background-color: rgba(214, 214, 214, 0.3);
}
/************************ Price picker ****************/
.price-range-filter .popover {
  font-size: 1em;
  left: 0px !important;
  top: -6px !important;
  z-index: 1;
}
.price-range-filter .popover .popover-body {
  width: 100%;
  min-width: 240px;
}
.price-range-filter .popover .arrow {
  display: none;
}
.popover {
  border-radius: 0;
  /* width: 100%;
     max-width: 315px;
     border: 0;
    */
}
.popover-header {
  border-radius: 0px;
}
.popover-content {
  padding: 0;
}
.popover.popover-bottom, .popover.bs-tether-element-attached-top {
  margin-top: 15px;
}
.dx-slider-handle {
  margin-top: -12px;
  border: 1px solid #0c576c;
  background-color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}
.dx-slider-range.dx-slider-range-visible {
  border: 1px solid #0c576c;
  background: #0c576c;
}
.dx-fieldset {
  margin: 10px 20px;
}
.dx-field {
  margin: 10px 0 10px 0;
}
.dx-state-hover.dx-slider-handle:not(.dx-state-active) {
  border: 1px solid #0c576c;
  background: #fff;
}
.popover.popover-bottom::before, .popover.bs-tether-element-attached-top::before {
  border-bottom-color: rgba(0, 0, 0, 0.08);
}
.popover.popover-bottom::after, .popover.bs-tether-element-attached-top::after {
  border-bottom-color: #fff;
}
/*.popover .arrow::before, .popover .arrow::after {
	 border: none;
}
*/
app-price-range-filter .btn:focus {
  outline: 1px dotted !important;
}
/***************************** Event Detail Page ****************/
.venue-map-showcase .nav-tabs {
  border: 0;
}
.venue-map-showcase .nav-tabs .nav-link {
  border-radius: 0;
  border: 0;
  font-size: 0.9em;
  color: #2e2e2e;
  padding: 0.9em 1.5em;
  background: #fff;
  margin-left: 2px;
  font-weight: 600;
}
.venue-map-showcase .nav-tabs .nav-link.active {
  background: #235053;
  color: #fff;
}
.venue-map-showcase .nav-tabs .nav-item.show .nav-link {
  background: #235053;
  color: #fff;
}
.venue-map-showcase .tab-content {
  text-align: center;
  margin-top: 4em;
}
/*********************************** Dynamic Map ****************/
#map_key {
  width: 100% !important;
  margin-top: 4em;
  text-align: center;
}
.map_key_row {
  float: inherit !important;
  display: inline-block;
  margin-right: 10px !important;
  width: auto !important;
}
.map_key_color {
  width: 12px !important;
}
#zoom_div {
  right: 1.2em;
  opacity: 0;
  top: 1.2em;
}
div#slider-vertical {
  display: none;
}
.tab-pane {
  position: relative;
}
#sectionTooltip .tpit_no_img {
  width: auto !important;
  background: transparent !important;
}
#sectionTooltip .tpit {
  background: transparent !important;
}
#wizard-screens .event-details p {
  margin: 0;
  color: #999;
  line-height: 1.2em;
  font-size: 0.85em;
  font-weight: 500;
}
#wizard-screens .card-header {
  padding: 0;
  background-color: #fff;
  border: 0;
  margin-top: 1em;
  border-radius: 0;
  border-bottom: 1px solid #d8d8d8;
  z-index: 2;
}
#wizard-screens #guest-list .card {
  border: 0;
  border-radius: 0;
  background-color: transparent;
}
#wizard-screens #guest-list .card-header a {
  color: #2e2e2e;
  font-weight: 600;
  text-decoration: none;
}
#wizard-screens #guest-list .card-header a:active, #wizard-screens #guest-list .card-header a:visited, #wizard-screens #guest-list .card-header a:hover {
  text-decoration: none;
}
#wizard-screens #guest-list .card-block {
  background-color: #fff;
}
#wizard-screens #guest-list .card-body {
  background-color: #fff;
  background-color: #fff;
  padding: 1.25rem;
}
.filter-nav .d-inline-block {
  display: block !important;
}
.center-block {
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}
.card img {
  position: initial;
}
div#sectionTooltip {
  display: none !important;
  position: absolute;
  width: 584px !important;
  height: 280px !important;
  left: 34.5% !important;
  top: 25.4% !important;
  border: none !important;
  border-radius: 0 !important;
}
.tpit {
  border-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: none !important;
  border: 0 !important;
}
.tpit .fancybox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.had, .lti {
  display: none;
}
.requesterEmail {
  font-size: 0.85em;
  clear: both;
  color: #2e2e2e;
}
.lb_img {
  position: relative;
  width: 100% !important;
  vertical-align: -webkit-baseline-middle;
  display: block;
}
div#MY_MAP {
  width: 100% !important;
  margin: auto;
}
div#MY_MAP svg, div#MY_MAP img {
  width: 100%;
}
.blue-popover ngb-popover-window {
  background: #07a;
  width: 420px;
  border-radius: 3px;
  font-family: 'Poppins', sans-serif !important;
}
.blue-popover ngb-popover-window h1 {
  color: #fff;
  font-weight: 600;
  font-size: 1.2em;
  height: 24px;
}
.blue-popover ngb-popover-window .popover-body {
  color: #fff;
  font-size: 0.95em;
}
.blue-popover .bs-popover-top .arrow:after {
  border-top-color: #07a;
}
.blue-popover .bs-popover-bottom .arrow:after {
  border-bottom-color: #07a;
}
.blue-popover .bs-popover-left .arrow:after {
  border-left-color: #07a;
}
.blue-popover .bs-popover-right .arrow:after {
  border-right-color: #07a;
}
@-moz-document url-prefix() {
  div#MY_MAP svg, div#MY_MAP img {
    height: auto !important;
  }
}
.map-container {
  height: auto !important;
}
#loc-search .dropdown-menu {
  border: 0;
  margin-left: -14px;
  margin-right: -14px;
  margin-top: 38px;
  width: 100%;
}
#loc-search .dropdown-item {
  padding: 8px 1.5rem;
  line-height: 1.35em;
  white-space: normal;
}
.sub-menu-parent #date-picker-custom form {
  height: 2.3em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.badge {
  color: #fff;
}
.invitee-search {
  z-index: 2;
}
.stylish-input-group ngb-typeahead-window.dropdown-menu .dropdown-item {
  line-height: 1em !important;
}
.add-guest-form .invitee-search ngb-typeahead-window.dropdown-menu .dropdown-item {
  line-height: 1em !important;
}
.search-events ngb-typeahead-window.dropdown-menu :last-child:not(ngb-highlight):not(span) {
  height: 3.5em !important;
  text-align: center !important;
  border-top: 2px solid #1a87cc !important;
  background-color: #fff !important;
}
.search-events ngb-typeahead-window.dropdown-menu .change-color {
  text-decoration: none !important;
  color: #1a87cc !important;
}
.search-events ngb-typeahead-window.dropdown-menu .dropdown-item .active {
  color: #1a87cc !important;
}
.dropdown-item {
  padding: 0.4rem 1.5rem;
}
.formDP {
  height: 3.15em;
  margin-bottom: 1em;
}
.formDP .btnpicker .mydpicon::before {
  padding-left: 18px;
  line-height: 3em;
}
.formDP .selection {
  width: 90% !important;
  padding: 0.64em !important;
  line-height: 1.6;
}
.formDP .mydp {
  width: 100% !important;
  border-radius: 0;
  margin-bottom: 1em;
}
.formDP .selectiongroup {
  width: 100%;
}
.formDP .mydp input.selection {
  font-weight: normal;
  font-size: 1.1em !important;
  font-family: var(--siteFont);
  background-color: transparent;
  border-radius: 0;
}
.notValid .mydp {
  border: 1px solid red;
}
.formDP.notValid {
  outline: 0 !important;
}
.no-padding {
  padding: 0px !important;
}
.grid {
  display: grid !important;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
  grid-auto-rows: 20px;
}
.ticket-group-delete .popover-body {
  padding: 1em;
  color: #2e2e2e;
  font-family: var(--siteFont);
  font-size: 0.9em;
}
.ticket-group-delete .popover-body h4 {
  font-size: 1.2em;
  font-weight: 600;
}
.ticket-group-delete .popover-body .cancel {
  color: #4c4c4c;
  background: #ccc;
  border: none;
  padding: 0.5em 1em;
  font-weight: 600;
  cursor: pointer;
}
.ticket-group-delete .popover-body .remove {
  color: #fff;
  background: #d44946;
  border: none;
  padding: 0.5em 1em;
  font-weight: 600;
  cursor: pointer;
}
.navbar-nav .dropdown-menu {
  position: absolute;
  float: none;
  line-height: normal;
  right: 22px;
  left: auto;
  top: 45px;
  padding: 0px;
}
.feed-toast .toast-body {
  padding: 0.75rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 768px) {
  my-date-picker.custom-2nd-width input.selection {
    width: 184px !important;
  }
}
#searh-list .btn-link {
  width: 100%;
  text-align: left;
  padding: 0;
  white-space: normal;
  font-size: 1em;
}
#guest-list .btn-link {
  font-size: 0.73em;
  width: 100%;
  white-space: normal;
  padding: 0;
  border: 0;
}
.btn-link:hover, .btn-link:active, .btn-link:visited, .btn-link:focus {
  color: #0056b3;
  text-decoration: none !important;
  background-color: transparent;
  border-color: transparent;
}
#companyNameDropDown .dropdown-menu {
  max-height: 250px;
  overflow-y: auto;
  -ms-overflow-style: scrollbar;
}
#companyIdDropDown .dropdown-menu {
  max-height: 250px;
  overflow-y: auto;
  -ms-overflow-style: scrollbar;
}
mwl-gauge {
  width: 250px;
  height: 140px;
  display: block;
  padding: 10px;
}
mwl-gauge svg {
  height: 230px;
}
mwl-gauge > .gauge > .dial {
  stroke: #dbecf8;
  stroke-width: 7;
  fill: rgba(0, 0, 0, 0);
}
mwl-gauge > .gauge > .value {
  stroke-width: 7;
  fill: rgba(0, 0, 0, 0);
}
.below-standard > mwl-gauge > .gauge > .value {
  stroke: #eea520;
}
.above-standard > mwl-gauge > .gauge > .value {
  stroke: #07a;
}
mwl-gauge > .gauge > .value-text {
  fill: #2e2e2e;
  font-family: var(--siteFont);
  font-weight: normal;
  font-size: 1em;
}
@media (max-width: 767px) {
  #wizard-screens #guest-list .card-header a {
    font-size: 1em;
  }
  #wizard-screens .event-details p {
    font-size: 0.73em;
    line-height: 1.15;
  }
  #wizard-screens .grid {
    display: block;
  }
  #wizard-screens .grid .item {
    max-width: 98%;
  }
  #loc-search .dropdown-menu {
    margin-left: auto;
    margin-right: auto;
  }
  .navbar {
    padding-left: 0;
  }
  .formDP .selection {
    width: 92% !important;
  }
}
@media only screen and (max-width: 576px) {
  #wizard-screens #guest-list .card-header a {
    font-size: 0.8rem;
  }
  .formDP .selection {
    width: 90% !important;
  }
}
@media only screen and (max-width: 1100px) {
  div#map_key {
    position: absolute;
    bottom: 0%;
  }
  .formDP .btnpicker .mydpicon::before {
    line-height: 2.5em;
  }
  .formDP .selection {
    width: 86% !important;
  }
}
/*@media only screen and (max-width: 991px) {
	 .stylish-input-group ngb-typeahead-window.dropdown-menu {
		 width: 83%;
	}
}
*/
@media only screen and (max-width: 990px) {
  div#MY_MAP {
    width: 100% !important;
  }
  div#map_key {
    position: absolute;
    left: 0;
    bottom: 15%;
    width: 100%;
  }
  .formDP .selection {
    width: 92% !important;
  }
}
@media (max-width: 767px) {
  .dx-slider-handle {
    width: 18px;
    height: 18px;
  }
  .popover {
    left: auto !important;
    right: 0;
  }
  .price-range-filter .popover {
    font-size: 1em;
    left: -79px !important;
    top: -6px !important;
  }
  .ng2-auto-complete-wrapper {
    width: 80%;
  }
  my-date-picker .mydp .icon-calendar, my-date-picker .mydp .icon-cross {
    font-size: 13px;
  }
}
/*@media only screen and (min-width: 500px) and (max-width: 767px) {
	 .stylish-input-group ngb-typeahead-window.dropdown-menu {
		 width: 87%;
	}
}
*/
@media only screen and (min-width: 401px) and (max-width: 767px) {
  div#map_key {
    bottom: 25%;
  }
  .shrink-header .stylish-input-group {
    width: 90%;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
  }
}
@media screen and (max-width: 479px) {
  .map_key_row {
    display: block;
    text-align: left;
  }
  my-date-picker .mydp .selectiongroup {
    width: 100%;
  }
  .popover::before, .popover::after {
    display: none;
  }
  .popover.popover-bottom, .popover.bs-tether-element-attached-top {
    margin-top: 0;
  }
  li.sub-menu-parent form my-date-picker .mydp {
    padding: 0;
  }
  .formDP .btnpicker .mydpicon::before {
    line-height: 2.4em;
  }
  .formDP .selection {
    width: 88% !important;
  }
}
/*@media only screen and (min-width: 320px) and (max-width: 400px) {
	 .stylish-input-group ngb-typeahead-window.dropdown-menu {
		 width: 80%;
	}
}
*/
@media only screen and (max-width: 400px) {
  .dx-slider-handle {
    margin-top: -9px;
  }
  .shrink-header .stylish-input-group {
    width: 85%;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
  }
  .navbar-nav .dropdown-menu {
    right: -50px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 420px) {
  .todo-menu .dropdown-menu {
    right: -101px;
    min-width: 300px !important;
  }
}
@media only screen and (max-width: 420px) {
  .card-nav {
    height: 68px;
  }
  .formDP .btnpicker .mydpicon::before {
    line-height: 2.4em;
  }
  .formDP .selection {
    width: 86% !important;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1160px) {
  div#sectionTooltip {
    width: 368px !important;
    height: 196px !important;
    left: 43% !important;
    top: 25.44% !important;
  }
}
@media only screen and (min-width: 1161px) and (max-width: 1199px) {
  div#sectionTooltip {
    width: 383px !important;
    height: 196px !important;
    left: 42% !important;
    top: 24.9% !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1260px) {
  div#sectionTooltip {
    position: absolute;
    width: 522px !important;
    height: 280px !important;
    left: 34.5% !important;
    top: 25.37% !important;
  }
  .formDP .btnpicker .mydpicon::before {
    line-height: 2.5em;
  }
  .formDP .selection {
    width: 89% !important;
  }
}
@media only screen and (min-width: 1259px) and (max-width: 1300px) {
  div#sectionTooltip {
    width: 560px !important;
    height: 280px !important;
    left: 34.5% !important;
    top: 25.4% !important;
  }
  .formDP .btnpicker .mydpicon::before {
    line-height: 2.7em;
  }
  .formDP .selection {
    width: 89% !important;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1900px) {
  .formDP .btnpicker .mydpicon::before {
    line-height: 2.7em;
  }
  .formDP .selection {
    width: 90% !important;
  }
}
@media only screen and (min-width: 1530px) {
  div#sectionTooltip {
    width: 634px !important;
    left: 35.5% !important;
    top: 25.389% !important;
  }
}
@media (max-width: 1099px) {
  div#sectionTooltip {
    width: 370px !important;
    height: 201px !important;
    left: 41.5% !important;
    top: 25.4% !important;
    border: none !important;
    border-radius: 0 !important;
  }
}
@media only screen and (min-width: 1630px) {
  div#sectionTooltip {
    width: 634px !important;
    left: 36.5% !important;
    top: 25.36% !important;
  }
}
@media only screen and (min-width: 1750px) {
  div#sectionTooltip {
    width: 634px !important;
    left: 37.3% !important;
    top: 25.351% !important;
  }
}
@media only screen and (min-width: 1900px) {
  div#sectionTooltip {
    left: 38% !important;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  div#MY_MAP svg, div#MY_MAP img {
    height: auto !important;
  }
  div#MY_MAP {
    width: 100% !important;
    margin: auto;
  }
  div#MY_MAP svg, div#MY_MAP img {
    width: 100%;
  }
  @-moz-document url-prefix() {
    div#MY_MAP svg, div#MY_MAP img {
      height: auto !important;
    }
  }
  #loc-search .dropdown-menu {
    border: 0;
    margin-left: -14px;
    margin-right: -14px;
    margin-top: 40px;
    width: 100%;
  }
  #loc-search .dropdown-item {
    padding: 8px 1.5rem;
    line-height: 1.35em;
    white-space: normal;
  }
  .sub-menu-parent #date-picker-custom form {
    height: 2.3em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  #leaveModal {
    overflow: auto;
    padding: 20px;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: var(--siteFont);
    font-size: 0.9em;
    line-height: normal;
    font-weight: 300;
    text-align: center;
  }
  #leaveModal .confirmText {
    padding: 0 0 20px 0;
  }
  .app-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(88, 88, 88, 0.8);
  }
  .app-overlay-content {
    position: fixed;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    color: #555;
  }
  .badge-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(88, 88, 88, 0.8);
  }
  .badge-overlay-content {
    position: fixed;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    color: #555;
  }
  .badge-package img {
    height: 10px;
  }
  .badge-package {
    background-color: #007faa;
  }
  .badge-package:hover {
    background-color: #007faa;
    outline: none;
  }
  #aprovalManagerDropDown .dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (-webkit-min-device-pixel-ratio: 0) {
  div#MY_MAP svg, div#MY_MAP img {
    height: auto !important;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .grid {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .grid .item {
    max-width: 48%;
  }
}
#leaveModal {
  overflow: auto;
  padding: 20px;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-family: var(--siteFont);
  font-size: 0.9em;
  line-height: normal;
  font-weight: 300;
  text-align: center;
}
#leaveModal .confirmText {
  padding: 0 0 20px 0;
}
.badge-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(88, 88, 88, 0.8);
}
.badge-overlay-content {
  position: fixed;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  color: #555;
}
.badge-package img {
  height: 10px;
}
.badge-package {
  background-color: #007faa;
}
.badge-package:hover {
  background-color: #007faa;
  outline: none;
}
#aprovalManagerDropDown .dropdown-menu {
  max-height: 250px;
  overflow-y: auto;
  -ms-overflow-style: scrollbar;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .grid {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .grid .item {
    max-width: 48%;
  }
}
#leaveModal {
  overflow: auto;
  padding: 20px;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-family: var(--siteFont);
  font-size: 0.9em;
  line-height: normal;
  font-weight: 300;
  text-align: center;
}
#leaveModal .confirmText {
  padding: 0 0 20px 0;
}
@media only screen and (max-width: 340px) and (min-width: 320px) {
  #guest-list .btn-link {
    font-size: 0.6em;
  }
}
.custom-popover {
  left: 4px !important;
  width: 14em !important;
  border: none;
}
.custom-popover .arrow {
  display: none;
}
.custom-popover .popover .arrow {
  display: none;
}
.custom-popover .popover-body a {
  color: #2e2e2e;
  font-weight: 500;
  margin-bottom: 7px;
  display: block;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
}
.invitee-popover {
  left: 4px !important;
  width: 14em !important;
}
.invitee-popover .popover-body a {
  color: #2e2e2e;
  font-weight: 500;
  margin-bottom: 7px;
  display: block;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
}
.pagination li a {
  border: none;
}
.page-link {
  line-height: 1.25;
  color: #007faa;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007faa;
  border-color: #007faa;
}
button:focus {
  outline: 0;
  outline: 0;
}
.card-top-border {
  border-top: 2px solid #367089;
}
.psLabel {
  background-color: #2a5b5c !important;
  left: -20px !important;
  right: auto !important;
}
.psLabel .popover-body {
  padding: 7px !important;
  color: #fff !important;
  line-height: 1em !important;
}
.psLabel .popover-body .customerName {
  font-weight: 600;
}
.psLabel .popover-body .psName {
  font-size: 0.8em;
}
.psLabel .arrow::after {
  border-bottom-color: #2a5b5c !important;
}
/********************************************** DX-DataGridView CSS [NG-6843: Sell Tickets] **********************************************/
.dx-datagrid-headers {
  background-color: #e7e7e7;
  border: none !important;
  color: #2e2e2e;
}
.dx-datagrid-headers td {
  border: none !important;
}
.dx-datagrid-rowsview {
  border-top: none !important;
}
tr.dx-header-row > td {
  font-weight: 700 !important;
  position: relative !important;
}
tr.dx-header-row > td .dx-sort-indicator {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.dx-widget {
  font-size: 1em !important;
  font-family: 'Poppins', sans-serif !important;
}
.dx-template-wrapper > tr {
  border-bottom: 1px solid #e7e7e7;
}
tr.dx-header-row > td, .dx-template-wrapper > tr > td {
  text-align: center !important;
}
.sell-ticket-modal .modal-dialog {
  max-width: 1050px;
}
tr.dx-header-row > td.thchk-ticket {
  padding-right: 0px !important;
  text-align: right !important;
}
tr.dx-header-row > td.thchk-ticket .custom-control-inline {
  margin-right: 0;
}
tr.dx-header-row > td.th-ticket {
  padding-left: 0px !important;
  text-align: left !important;
}
.dx-template-wrapper > tr > td.tdchk-ticket {
  text-align: right !important;
  padding-right: 0px !important;
}
.dx-template-wrapper > tr > td.tdchk-ticket .custom-control-inline {
  margin-right: 0;
}
.dx-template-wrapper > tr > td.tdTickets {
  text-align: left !important;
  padding-left: 15px !important;
}
tr.dx-header-row > td.thchk-event {
  text-align: left !important;
}
#gridContainer .dx-column-indicators {
  float: none !important;
  position: absolute !important;
  right: 0 !important;
  display: inline !important;
}
#gridContainer [role='columnheader'] {
  text-align: center important;
}
.ngx-pagination {
  padding: 0 !important;
}
.ngx-pagination .current {
  background: #07a !important;
}
.dx-datagrid .dx-row > td {
  padding: 15px !important;
}
.selectTickets .custom-control-label {
  margin-top: 3px !important;
}
.selectTickets .custom-control-label::after {
  width: 1rem !important;
  height: 1.4rem !important;
  top: -0.3rem !important;
  left: -1.6rem !important;
}
.selectTickets .custom-control-label::before {
  top: 0rem !important;
  width: 0.8rem !important;
  height: 0.8rem !important;
  border-radius: 0 !important;
}
.selectTickets .custom-control {
  min-height: 1.1rem !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #07a !important;
  background-color: #07a !important;
}
.sell-ticket .close {
  color: #000 !important;
  font-size: 1.3rem;
}
.dx-datagrid .dx-column-lines > td {
  border-left: none !important;
  border-right: none !important;
}
.dx-datagrid-nodata {
  visibility: hidden !important;
}
.dx-overlay.dx-widget {
  visibility: hidden !important;
}
.errorListing tr, .successListing tr {
  border-bottom: none !important;
}
.errorListing tr.dx-header-row > td.th-ticket, .successListing tr.dx-header-row > td.th-ticket {
  padding-left: 7px !important;
}
.dx-datagrid-table .td-align-left {
  text-align: left !important;
}
.error-color {
  color: #e62600 !important;
}
.success-color {
  color: #54b157 !important;
}
ngb-popover-window.infoPopover {
  left: -78px !important;
  max-width: 320px !important;
}
ngb-popover-window.infoPopover .arrow {
  left: 90% !important;
}
ngb-popover-window.infoPopover ul {
  padding: 10px 15px;
  margin: 0px;
}
ngb-popover-window.infoPopover ul > :first-child {
  margin-bottom: 7px;
}
.dx-freespace-row {
  display: none !important;
}
ngb-popover-window.sdInfoPopover {
  left: 34px !important;
  border-radius: 5px !important;
}
ngb-popover-window.sdInfoPopover .popover-body {
  background: #303030;
  color: #fefefe !important;
  border-radius: 3px !important;
}
ngb-popover-window.sdInfoPopover .arrow::after {
  border-bottom-color: #303030 !important;
}
/********************************************/
@media only screen and (max-width: 767px) and (min-width: 270px) {
  html {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  my-date-picker .custom-2nd-width input .selection {
    width: auto !important;
  }
}
::ng-deep .ifrm-modal-width .modal-dialog {
  max-width: 90% !important;
  width: 90% !important;
  margin: 1.75rem auto;
}
.venue-map {
  height: 500px !important;
}
@media screen and (max-width: 823px) {
  /*.map-list-ctn {
         overflow: visible;
    }
    */
  .venue-map-reset {
    left: unset !important;
    right: 0 !important;
    top: 0 !important;
  }
  .map-ctn {
    position: unset !important;
  }
}
@media screen and (max-width: 991px) and (min-aspect-ratio: 1.4444444444) {
  .map-ctn {
    width: 100% !important;
  }
}
a#sea-seatics-link {
  visibility: hidden !important;
}
#venue-map-zoom-controls {
  visibility: hidden !important;
}
.sea-map-inner > svg:first-child > g > path:first-child {
  fill: #f2f2f2 !important;
}
#novalnet-iframe-element {
  width: 100% !important;
  min-height: 100% !important;
}

